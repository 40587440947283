import * as React from 'react';

import { Link } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../hooks/useMedias';
import ButtonLink from '../LinkButton/LinkButton';

import './MemberProfileBlock.scss';

const MemberProfileBlock = ({ visuel, alt, fullname, post, link }) => {
  const intl = useIntl();
  const { processUrl } = useMedias();

  return (
    <div className="component_member_profile_block">
      <div className="visuel">
        {visuel && (
          <>
            <div className="image-wrapper">
              <img
                className="only_mobile_tablet"
                src={processUrl(visuel?.image_style_uri?.gatsby_profile_thumbnail)}
                alt={alt}
                style={{ height: '100%', width: '100', objectFit: 'cover' }}
              />
            </div>
            <Link to={link} className="only_desktop">
              <div className="image-wrapper">
                <img
                  src={processUrl(visuel?.image_style_uri?.gatsby_profile_thumbnail)}
                  alt={alt}
                  style={{ height: '100%', width: '100', objectFit: 'cover' }}
                />
              </div>
            </Link>
          </>
        )}
      </div>
      <div className="info_member">
        <p className="big">{fullname}</p>
        <p>{post}</p>
      </div>
      {link && (
        <div className="btn_container">
          <ButtonLink
            label={intl.formatMessage({ id: 'common.see_biography' })}
            link={link}
            ariaLabel={`${intl.formatMessage({
              id: 'ariaLabel.see_biography_of',
            })} ${fullname}`}
          />
        </div>
      )}
    </div>
  );
};

MemberProfileBlock.defaultProps = {
  name: 'Default Nmae',
  post: 'Default Post',
};

export default MemberProfileBlock;
